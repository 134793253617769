/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import axios from "./axios.js";
import routerGuard from "./router/routerGuard";
import RouteViewComponent from "./router/routerView";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "main",
          meta: { url: "/", parent: "" },
          // redirect: "/pages/login"
          component: () => import("./views/pages/Main.vue"),
        },
        {
          path: "/home",
          name: "home",
          component: () => import("./views/pages/Main.vue"),
        },
        {
          path: "/home",
          name: "home",
          component: () => import("./views/pages/Main.vue"),
        },
        {
          path: "profile",
          component: () => import("./views/profile"),
          children: [
            {
              path: "profile",
              name: "profile",
              component: () => import("./views/profile/profile.vue"),
            },
            {
              path: "edit",
              name: "edit",
              component: () => import("./views/profile/edit.vue"),
            },
            {
              path: "change-password",
              name: "change-password",
              component: () => import("./views/profile/change_password.vue"),
            },
          ],
        },
        {
          path: "tms",
          component: () => import("./views/tms"),
          children: [
            //=================== route for delivery code ================

            {
              path: "driver",
              name: "driver",
              component: () => import("./views/tms/driver/index.vue"),
            },
            {
              path: "transport-plan",
              name: "transport-plan",
              component: () => import("./views/tms/transport-plan/index.vue"),
            },
            {
              path: "transport-pickup",
              name: "transport-pickup",
              component: () => import("./views/tms/transport-pickup/index.vue"),
            },
            {
              path: "change-vehicle",
              name: "change-vehicle",
              component: () => import("./views/tms/change-vehicle/index.vue"),
            },
            {
              path: "vehicle-reassigment",
              name: "vehicle-reassigment",
              component: () =>
                import("./views/tms/vehicle-reassigment/index.vue"),
            },
            {
              path: "vehicle-availability",
              name: "vehicle-availability",
              component: () =>
                import("./views/tms/vehicle-availability/index.vue"),
            },
            {
              path: "vehicle-availability/create",
              name: "vehicle-availability-create",
              component: () =>
                import("./views/tms/vehicle-availability/create.vue"),
            },
            {
              path: "vehicle-availability/edit",
              name: "vehicle-availability-edit",
              component: () =>
                import("./views/tms/vehicle-availability/edit.vue"),
            },
            {
              path: "driver-availability",
              name: "driver-availability",
              component: () =>
                import("./views/tms/driver-availability/index.vue"),
            },
            {
              path: "do-reassigment",
              name: "do-reassigment",
              component: () => import("./views/tms/do-reassigment/index.vue"),
            },
          ],
        },
        {
          path: "pod",
          component: () => import("./views/pod"),
          children: [
            //=================== route for delivery code ================
            {
              path: "proof-of-delivery",
              name: "proof-of-delivery",
              component: () => import("./views/pod/pod-master/index.vue"),
            },
            {
              path: "proof-of-delivery/edit",
              name: "proof-of-delivery-edit",
              component: () => import("./views/pod/pod-master/editV3.vue"),
            },
            {
              path: "proof-of-delivery/edit",
              name: "Eproof-of-delivery-edit",
              component: () => import("./views/pod/pod-master/editEpodV4.vue"),
            },
            {
              path: "proof-of-delivery/show",
              name: "proof-of-delivery-show",
              component: () => import("./views/pod/pod-master/showV3.vue"),
            },
          ],
        },
        {
          path: "approval",
          component: () => import("./views/approval"),
          children: [
            {
              path: "simple-sto",
              component: () => import("./views/approval"),
              children: [
                {
                  path: "",
                  name: "approval.simple-sto",
                  component: () =>
                    import("./views/approval/simple-sto/index.vue"),
                },
                {
                  path: "detail/:id",
                  name: "approval.simple-sto-detail",
                  component: () =>
                    import("./views/approval/simple-sto/detail.vue"),
                },
              ],
            },
            /* {
              path: "inter-storage",
              component: () => import("./views/approval"),
              children: [
                {
                  path: "",
                  name: "approval.inter-storage",
                  component: () =>
                    import("./views/approval/inter-storage/index.vue"),
                },
              ],
            }, */
            {
              path: "stock-transfer-order",
              component: () => import("./views/approval"),
              children: [
                {
                  path: "",
                  name: "approval.stock-transfer-order",
                  component: () =>
                    import("./views/approval/stock-transfer-order/index.vue"),
                },
                {
                  path: "detail/:id",
                  name: "approval.stock-transfer-order-detail",
                  component: () =>
                    import("./views/approval/stock-transfer-order/detail.vue"),
                },
              ],
            },
            {
              path: "inter-storage",
              component: () => import("./views/approval"),
              children: [
                {
                  path: "",
                  name: "approval.inter-storage",
                  component: () =>
                    import("./views/approval/inter-storage/index.vue"),
                },
                {
                  path: "detail/:id",
                  name: "approval.inter-storage-detail",
                  component: () =>
                    import("./views/approval/inter-storage/detail.vue"),
                },
              ],
            },
            //approval work order
            {
              path: "work-order",
              component: () => import("./views/approval"),
              children: [
                {
                  path: "",
                  name: "approval.work-order",
                  component: () =>
                    import("./views/approval/work-order/index.vue"),
                },
              ],
            },
            // approval history
            {
              path: "history",
              component: () => import("./views/approval"),
              children: [
                {
                  path: "",
                  name: "approval.history",
                  component: () =>
                    import("./views/approval/approval-history/index.vue"),
                },
              ],
            }
          ],
        },
        {
          path: "outbound",
          component: () => import("./views/outbound"),
          children: [
            {
              path: "simple-sto-stock-out",
              component: () => import("./views/outbound"),
              children: [
                {
                  path: "",
                  name: "outbound.simple-sto-stock-out",
                  component: () =>
                    import("./views/outbound/simple-sto-stock-out/index.vue"),
                },
                {
                  path: "create",
                  name: "outbound.simple-sto-stock-out-create",
                  component: () =>
                    import("./views/outbound/simple-sto-stock-out/Form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "outbound.simple-sto-stock-out-edit",
                  component: () =>
                    import("./views/outbound/simple-sto-stock-out/Form.vue"),
                },
                {
                  path: "detail/:id",
                  name: "outbound.simple-sto-stock-out-detail",
                  component: () =>
                    import("./views/outbound/simple-sto-stock-out/detail.vue"),
                },
                {
                  path: "import",
                  name: "outbound.simple-sto-stock-out-import",
                  component: () =>
                    import(
                      "./views/outbound/simple-sto-stock-out/formImport.vue"
                    ),
                },
              ],
            },
            {
              path: "simple-sto",
              component: () => import("./views/outbound"),
              children: [
                {
                  path: "",
                  name: "outbound.simple-sto",
                  component: () =>
                    import("./views/outbound/simple-sto/index.vue"),
                },
                {
                  path: "create",
                  name: "outbound.simple-sto-create",
                  component: () =>
                    import("./views/outbound/simple-sto/Form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "outbound.simple-sto-edit",
                  component: () =>
                    import("./views/outbound/simple-sto/Form.vue"),
                },
                {
                  path: "detail/:id",
                  name: "outbound.simple-sto-detail",
                  component: () =>
                    import("./views/outbound/simple-sto/detail.vue"),
                },
                {
                  path: "import",
                  name: "outbound.simple-sto-import",
                  component: () =>
                    import("./views/outbound/simple-sto/formImport.vue"),
                },
                {
                  path: "emergency",
                  name: "outbound.simple-sto.emergency",
                  component: () =>
                    import("./views/outbound/simple-sto/emergencyForm.vue"),
                },
              ],
            },
            {
              path: "stock-transfer-order-planner",
              component: () => import("./views/outbound"),
              children: [
                {
                  path: "",
                  name: "outbound.stock-transfer-order-planner",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-planner/index.vue"
                    ),
                },
                {
                  path: "detail-suggestion/:id",
                  name: "outbound.stock-transfer-order-planner-suggestion-detail",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-planner/detailSuggestion.vue"
                    ),
                },
                {
                  path: "detail-process/:id",
                  name: "outbound.stock-transfer-order-planner-process-detail",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-planner/detailProcess.vue"
                    ),
                },
                {
                  path: "detail-handover/:id",
                  name: "outbound.stock-transfer-order-planner-handover-detail",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-planner/detailHandover.vue"
                    ),
                },
                {
                  path: "detail-complete/:id",
                  name: "outbound.stock-transfer-order-planner-complete-detail",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-planner/detailComplete.vue"
                    ),
                },
              ],
            },
            {
              path: "stock-transfer-order-processing",
              component: () => import("./views/outbound"),
              children: [
                {
                  path: "",
                  name: "outbound.stock-transfer-order-processing",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-processing/index.vue"
                    ),
                },
                {
                  path: "picking-form/:id",
                  name: "outbound.stock-transfer-order-processing-edit",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-processing/Form.vue"
                    ),
                },
                {
                  path: "detail-process/:id",
                  name: "outbound.stock-transfer-order-processing-process-detail",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-processing/detail.vue"
                    ),
                },
                {
                  path: "detail-handover/:id",
                  name: "outbound.stock-transfer-order-processing-handover-detail",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-processing/detailHandover.vue"
                    ),
                },
                {
                  path: "detail-complete/:id",
                  name: "outbound.stock-transfer-order-processing-complete-detail",
                  component: () =>
                    import(
                      "./views/outbound/stock-transfer-order-processing/detailComplete.vue"
                    ),
                },
              ],
            },
            {
              path: "simple-sto-do",
              component: () => import("./views/outbound"),
              children: [
                {
                  path: "",
                  name: "outbound.simple-sto-do",
                  component: () =>
                    import("./views/outbound/simple-sto-do/index.vue"),
                },
                {
                  path: "edit/:id",
                  name: "outbound.simple-sto-do.edit",
                  component: () =>
                    import("./views/outbound/simple-sto-do/Form.vue"),
                },
                {
                  path: "detail/:id",
                  name: "outbound.simple-sto-do.detail",
                  component: () =>
                    import("./views/outbound/simple-sto-do/detail.vue"),
                },
              ],
            },
            {
              path: "simple-outbound-processing",
              component: () => import("./views/outbound"),
              children: [
                {
                  path: "",
                  name: "simple-outbound-processing-index",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-processing/index.vue"
                    ),
                },
                // {
                //   path: "create",
                //   name: "simple-outbound-processing-create",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-processing/Form.vue"),
                // },
                // {
                //   path: "edit-sug-data",
                //   name: "simple-outbound-processing-suggestion-edit",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-processing/FormSuggestion.vue"),
                // },
                // {
                //   path: "view-process-data",
                //   name: "simple-outbound-processing-process-view",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-processing/FormProcess.vue"),
                // },
                // {
                //   path: "view-handover-data",
                //   name: "simple-outbound-processing-handover-view",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-processing/FormHandover.vue"),
                // },
                {
                  path: "create",
                  name: "simple-outbound-processing-create",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-processing/Form.vue"
                    ),
                },
                {
                  path: "view-picking-data",
                  name: "simple-outbound-processing-new-view",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-processing/FormNew.vue"
                    ),
                },
                {
                  path: "view-process-data",
                  name: "simple-outbound-processing-process-view",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-processing/FormProcess.vue"
                    ),
                },
                {
                  path: "view-handover-data",
                  name: "simple-outbound-processing-handover-view",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-processing/FormHandover.vue"
                    ),
                },
              ],
            },
            {
              path: "simple-outbound-planner",
              component: () => import("./views/outbound"),
              children: [
                {
                  path: "",
                  name: "simple-outbound-planner-index",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-planner/index.vue"
                    ),
                },
                // {
                //   path: "create",
                //   name: "simple-outbound-planner-create",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-planner/Form.vue"),
                // },
                // {
                //   path: "view-picking-data",
                //   name: "simple-outbound-planner-new-view",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-planner/FormNew.vue"),
                // },
                // {
                //   path: "view-process-data",
                //   name: "simple-outbound-planner-process-view",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-planner/FormProcess.vue"),
                // },
                // {
                //   path: "view-handover-data",
                //   name: "simple-outbound-planner-handover-view",
                //   component: () =>
                //     import("./views/outbound/simple-outbound-planner/FormHandover.vue"),
                // },
                {
                  path: "create",
                  name: "simple-outbound-planner-create",
                  component: () =>
                    import("./views/outbound/simple-outbound-planner/Form.vue"),
                },
                {
                  path: "edit-sug-data",
                  name: "simple-outbound-planner-suggestion-edit",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-planner/FormSuggestion.vue"
                    ),
                },
                {
                  path: "view-process-data",
                  name: "simple-outbound-planner-process-view",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-planner/FormProcess.vue"
                    ),
                },
                {
                  path: "view-handover-data",
                  name: "simple-outbound-planner-handover-view",
                  component: () =>
                    import(
                      "./views/outbound/simple-outbound-planner/FormHandover.vue"
                    ),
                },
              ],
            },
            {
              path: "supplier-return",
              component: () => import("./views/outbound/supplier-return"),
              children: [
                {
                  path: "",
                  name: "outbound.supplier-return.index",
                  component: () =>
                    import("./views/outbound/supplier-return/Main.vue"),
                },
                {
                  path: "form/:id",
                  name: "outbound.supplier-return.form",
                  component: () =>
                    import("./views/outbound/supplier-return/Form.vue"),
                },
                {
                  path: "detail/:id",
                  name: "outbound.supplier-return.detail",
                  component: () =>
                    import("./views/outbound/supplier-return/Detail.vue"),
                },
                {
                  path: "handover",
                  name: "outbound.supplier-return.handover",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/handovers/Main.vue"
                    ),
                },
                {
                  path: "handover/edit/:id",
                  name: "outbound.supplier-return.handover-edit",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/handovers/Form.vue"
                    ),
                },
                {
                  path: "handover/detail/:id",
                  name: "outbound.supplier-return.handover-detail",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/handovers/Detail.vue"
                    ),
                },
                {
                  path: "reversal",
                  name: "outbound.supplier-return.reversal",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/reversal/Main.vue"
                    ),
                },
                {
                  path: "reversal/detail/:id",
                  name: "outbound.supplier-return.reversal-detail",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/reversal/Detail.vue"
                    ),
                },
                {
                  path: "print/:id",
                  name: "outbound.supplier-return.print",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/components/print.vue"
                    ),
                },
                {
                  path: "print-bapp/:id",
                  name: "outbound.supplier-return.print-bapp",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/components/printBapp.vue"
                    ),
                },
                {
                  path: "print-bastp/:id",
                  name: "outbound.supplier-return.print-bastp",
                  component: () =>
                    import(
                      "./views/outbound/supplier-return/components/printBastp.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "reversal",
          component: () => import("./views/reversal"),
          children: [
            {
              path: "gr",
              component: () => import("./views/reversal"),
              children: [
                {
                  path: "",
                  name: "reversal.gr.list",
                  component: () =>
                    import("./views/reversal/good-receive/List.vue"),
                },
                {
                  path: "form",
                  name: "reversal.gr.form",
                  component: () =>
                    import("./views/reversal/good-receive/Form.vue"),
                },
                {
                  path: "approval",
                  name: "reversal.gr.approval",
                  component: () =>
                    import("./views/reversal/good-receive/approval/index.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "inbound",
          component: () => import("./views/inbound"),
          children: [
            {
              path: "simple-sto",
              component: () => import("./views/inbound"),
              children: [
                {
                  path: "",
                  name: "inbound.simple-sto",
                  component: () =>
                    import("./views/inbound/simple-sto/index.vue"),
                },
                {
                  path: "create",
                  name: "inbound.simple-sto-create",
                  component: () =>
                    import("./views/inbound/simple-sto/Form.vue"),
                },
                {
                  path: "create",
                  name: "inbound.simple-sto-create",
                  component: () =>
                    import("./views/inbound/simple-sto/Form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "inbound.simple-sto-edit",
                  component: () =>
                    import("./views/inbound/simple-sto/Form.vue"),
                },
              ],
            },
            // {
            //   path: "sales-return-edit-receive/:id",
            //   name: "inbound.receive.sales-return-edit-receive",
            //   component: () =>
            //     import("./views/inbound/receive/sales-return/FormReceive.vue"),
            // },
            {
              path: "receive",
              component: () => import("./views/inbound/receive"),
              children: [
                {
                  path: "",
                  name: "inbound.receive",
                  component: () => import("./views/inbound/receive/Main.vue"),
                },
                {
                  path: "sales-return",
                  component: () =>
                    import("./views/inbound/receive/sales-return"),
                  children: [
                    {
                      path: "",
                      name: "inbound.receive.sales-return",
                      component: () =>
                        import("./views/inbound/receive/sales-return/Main.vue"),
                    },
                    // {
                    //   path: "create",
                    //   name: "inbound.receive.sales-return-create",
                    //   component: () =>
                    //     import("./views/inbound/receive/sales-return/FormReceive.vue"),
                    // },
                    {
                      path: "edit-receive/:id",
                      name: "inbound.receive.sales-return-edit-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/sales-return/FormEditReceive.vue"
                        ),
                    },
                    {
                      path: "create-receive/:id",
                      name: "inbound.receive.sales-return-create-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/sales-return/FormReceive.vue"
                        ),
                    },
                    {
                      path: "print-receive/:id",
                      name: "inbound.receive.sales-return-print-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/sales-return/PrintReceive.vue"
                        ),
                    },
                    {
                      path: "edit-putaway/:id",
                      name: "inbound.receive.sales-return-edit-putaway",
                      component: () =>
                        import(
                          "./views/inbound/receive/sales-return/FormPutaway.vue"
                        ),
                    },
                    {
                      path: "print-putaway/:id",
                      name: "inbound.receive.sales-return-print-putaway",
                      component: () =>
                        import(
                          "./views/inbound/receive/sales-return/PrintPutaway.vue"
                        ),
                    },
                    {
                      path: "edit-complete/:id",
                      name: "inbound.receive.sales-return-edit-complete",
                      component: () =>
                        import(
                          "./views/inbound/receive/sales-return/FormComplete.vue"
                        ),
                    },
                    {
                      path: "print-complete/:id",
                      name: "inbound.receive.sales-return-print-complete",
                      component: () =>
                        import(
                          "./views/inbound/receive/sales-return/PrintComplete.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "purchase-order",
                  component: () =>
                    import("./views/inbound/receive/purchase-order"),
                  children: [
                    {
                      path: "",
                      name: "inbound.receive.purchase-order",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/Main.vue"
                        ),
                    },
                    {
                      path: "create-receive/:id",
                      name: "inbound.receive.purchase-order.create-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/CreateReceiveForm.vue"
                        ),
                    },
                    {
                      path: "print-receive/:id",
                      name: "inbound.receive.purchase-order.print-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/PrintReceive.vue"
                        ),
                    },
                    {
                      path: "convert-hu/:id",
                      name: "inbound.receive.purchase-order.convert-hu",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/HuConversionForm.vue"
                        ),
                    },
                    {
                      path: "print-hu/:id",
                      name: "inbound.receive.purchase-order.print-hu",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/PrintHUConversion.vue"
                        ),
                    },
                    {
                      path: "create-put-away/:id",
                      name: "inbound.receive.purchase-order.create-put-away",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/PutAwayForm.vue"
                        ),
                    },
                    {
                      path: "print-put-away/:id",
                      name: "inbound.receive.purchase-order.print-put-away",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/PrintPutAway.vue"
                        ),
                    },
                    {
                      path: "create-complete/:id",
                      name: "inbound.receive.purchase-order.create-complete",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/CompleteForm.vue"
                        ),
                    },
                    {
                      path: "print-complete/:id",
                      name: "inbound.receive.purchase-order.print-complete",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/PrintCompleted.vue"
                        ),
                    },
                    {
                      path: "import-detail/:id",
                      name: "inbound.receive.purchase-order.import-detail",
                      component: () =>
                        import(
                          "./views/inbound/receive/purchase-order/LogImportDetail.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "customer-return",
                  component: () =>
                    import("./views/inbound/receive/customer-return"),
                  children: [
                    {
                      path: "",
                      name: "inbound.receive.customer-return",
                      component: () =>
                        import(
                          "./views/inbound/receive/customer-return/Main.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "inter-warehouse",
                  component: () =>
                    import("./views/inbound/receive/inter-warehouse"),
                  children: [
                    {
                      path: "",
                      name: "inbound.receive.inter-warehouse",
                      component: () =>
                        import(
                          "./views/inbound/receive/inter-warehouse/Main.vue"
                        ),
                    },
                    {
                      path: "receive/:id",
                      name: "inbound.receive.inter-warehouse.create-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/inter-warehouse/CreateReceiveForm.vue"
                        ),
                    },
                    {
                      path: "print-receive/:id",
                      name: "inbound.receive.inter-warehouse.print-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/inter-warehouse/PrintReceive.vue"
                        ),
                    },
                    {
                      path: "put-away/:id",
                      name: "inbound.receive.inter-warehouse.put-away",
                      component: () =>
                        import(
                          "./views/inbound/receive/inter-warehouse/PutAwayForm.vue"
                        ),
                    },
                    {
                      path: "print-put-away/:id",
                      name: "inbound.receive.inter-warehouse.print-put-away",
                      component: () =>
                        import(
                          "./views/inbound/receive/inter-warehouse/PrintPutAway.vue"
                        ),
                    },
                    {
                      path: "complete/:id",
                      name: "inbound.receive.inter-warehouse.completed",
                      component: () =>
                        import(
                          "./views/inbound/receive/inter-warehouse/CompletedForm.vue"
                        ),
                    },
                    {
                      path: "print-complete/:id",
                      name: "inbound.receive.inter-warehouse.print-completed",
                      component: () =>
                        import(
                          "./views/inbound/receive/inter-warehouse/PrintCompleted.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "pod-return",
                  component: () => import("./views/inbound/receive/pod-return"),
                  children: [
                    {
                      path: "",
                      name: "inbound.receive.pod-return",
                      component: () =>
                        import("./views/inbound/receive/pod-return/Main.vue"),
                    },
                    {
                      path: "form-receive/:id/:tab",
                      name: "inbound.receive.pod-return.form-receive",
                      component: () =>
                        import(
                          "./views/inbound/receive/pod-return/EditReceiveForm.vue"
                        ),
                    },
                    {
                      path: "form-putaway/:id/:tab",
                      name: "inbound.receive.pod-return.form-putaway",
                      component: () =>
                        import(
                          "./views/inbound/receive/pod-return/EditPutawayForm.vue"
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: "simple-sto-stock-in",
              component: () => import("./views/inbound"),
              children: [
                {
                  path: "",
                  name: "inbound.simple-sto-stock-in",
                  component: () =>
                    import("./views/inbound/simple-sto-stock-in/index.vue"),
                },
                {
                  path: "detail/:id",
                  name: "inbound.simple-sto-stock-in-detail",
                  component: () =>
                    import("./views/inbound/simple-sto-stock-in/detail.vue"),
                },
                {
                  path: "reversal/:id",
                  name: "inbound.simple-sto-stock-in-reversal",
                  component: () =>
                    import("./views/inbound/simple-sto-stock-in/reversal.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "purchase",
          component: () => import("./views/purchase"),
          children: [
            {
              path: "",
              name: "purchase",
              component: () => import("./views/purchase/index.vue"),
            },
            {
              path: "po-return",
              name: "purchase.po-return",
              component: () => import("./views/purchase/purchase-order-return"),
              children: [
                {
                  path: "",
                  name: "purchase.po-return.index",
                  component: () =>
                    import("./views/purchase/purchase-order-return/Main.vue"),
                },
                {
                  path: "draft",
                  name: "purchase.po-return.draft",
                  component: () =>
                    import("./views/purchase/purchase-order-return/form.vue"),
                },
                {
                  path: "approval",
                  name: "purchase.po-return.approval",
                  component: () =>
                    import(
                      "./views/purchase/purchase-order-return/approval/index.vue"
                    ),
                },
                {
                  path: "approval/detail/:id/:po",
                  name: "purchase.po-return.approval.detail",
                  component: () =>
                    import(
                      "./views/purchase/purchase-order-return/approval/newDetail.vue"
                    ),
                },
                {
                  path: "draft/:id/:po",
                  name: "purchase.po-return.draft-edit",
                  component: () =>
                    import("./views/purchase/purchase-order-return/form.vue"),
                },
                {
                  path: "detail/:id/:po",
                  name: "purchase.po-return.detail",
                  component: () =>
                    import("./views/purchase/purchase-order-return/detail.vue"),
                },
                {
                  path: "print/:id/:po",
                  name: "purchase.po-return.print",
                  component: () =>
                    import("./views/purchase/purchase-order-return/Print.vue"),
                },
                {
                  path: "emergency",
                  name: "purchase.po-return.emergency-form",
                  component: () =>
                    import(
                      "./views/purchase/purchase-order-return/emergencyForm.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "purchase-order",
          component: () => import("./views/purchase-order"),
          children: [
            {
              path: "",
              name: "purchase-order.list",
              component: () => import("./views/purchase-order/List.vue"),
            },
            {
              path: "approval",
              name: "purchase-order.list-approval",
              component: () =>
                import("./views/purchase-order/approval/index.vue"),
            },
            {
              path: "approval/detail/:id",
              name: "purchase-order.list-approval-detail",
              component: () =>
                import("./views/purchase-order/approval/Detail.vue"),
            },
            {
              path: "create",
              name: "purchase-order.list-create",
              component: () => import("./views/purchase-order/Form.vue"),
            },
            {
              path: "edit/:id",
              name: "purchase-order.list-edit",
              component: () => import("./views/purchase-order/Form.vue"),
            },
            {
              path: "amend/:id",
              name: "purchase-order.list-amend",
              component: () =>
                import("./views/purchase-order/AmendFormEdit.vue"),
            },
            {
              path: "detail/:id",
              name: "purchase-order.list-detail",
              component: () => import("./views/purchase-order/Detail.vue"),
            },
            {
              path: "inquiry/:id",
              name: "purchase-order.list-inquiry",
              component: () => import("./views/purchase-order/Form.vue"),
            },
            {
              path: "import-log/:id",
              name: "purchase-order.list.import-log-detail",
              component: () =>
                import("./views/purchase-order/LogImportDetail.vue"),
            },
          ],
        },
        {
          path: "stock-opname",
          component: () => import("./views/stockOpname"),
          children: [
            //=================== route for stock opname ================
            {
              path: "/sof/manage-sof",
              name: "sof",
              component: () =>
                import("./views/stockOpname/manager-stock-opname/index.vue"),
            },
            {
              path: "/sof/manage-sof/create",
              name: "sof-create",
              component: () =>
                import("./views/stockOpname/manager-stock-opname/create.vue"),
            },
            {
              path: "/sof/manage-sof/edit",
              name: "sof-edit",
              component: () =>
                import("./views/stockOpname/manager-stock-opname/create.vue"),
            },
            {
              path: "/sof/manage-sof/view",
              name: "sof-view",
              component: () =>
                import("./views/stockOpname/manager-stock-opname/show.vue"),
            },
            {
              path: "/sof/manage-sof/views",
              name: "sof-show",
              component: () =>
                import("./views/stockOpname/manager-stock-opname/Shows.vue"),
            },
            {
              path: "/execute-sof/manage-execute-sof",
              name: "execute-sof",
              component: () =>
                import("./views/stockOpname/staff-stock-opname/index.vue"),
            },
          ],
        },
        {
          path: "stock-opname-staff",
          component: () => import("./views/stockOpname"),
          children: [
            //=================== route for stock opname ================
            {
              path: "staff-stock-opname",
              name: "/execute-sof/manage-execute-sof",
              component: () =>
                import("./views/stockOpname/staff-stock-opname/index.vue"),
            },
            {
              path: "staff-stock-opname/show",
              name: "/execute-sof/manage-execute-sof-show",
              component: () =>
                import("./views/stockOpname/staff-stock-opname/show.vue"),
            },
            {
              path: "staff-stock-opname/show",
              name: "/execute-sof/manage-execute-sof-show2",
              component: () =>
                import("./views/stockOpname/staff-stock-opname/show2.vue"),
            },
            {
              path: "staff-stock-opname/count",
              name: "/execute-sof/manage-execute-sof-count",
              component: () =>
                import("./views/stockOpname/staff-stock-opname/count.vue"),
            },
            {
              path: "staff-stock-opname/import",
              name: "/execute-sof/manage-execute-sof-import",
              component: () =>
                import(
                  "./views/stockOpname/staff-stock-opname/import/index.vue"
                ),
            },
          ],
        },
        {
          // SAF ROUTE
          path: "saf",
          component: () => import("./views/stockOpname"),
          children: [
            {
              path: "/saf/manage-saf",
              name: "saf",
              component: () => import("./views/stockOpname/saf/index.vue"),
            },
            {
              path: "/saf/manage-saf/create",
              name: "saf-create",
              component: () => import("./views/stockOpname/saf/Create.vue"),
            },
            {
              path: "/saf/manage-saf/view",
              name: "saf-view",
              component: () => import("./views/stockOpname/saf/ViewDetail.vue"),
            },
            {
              path: "/saf/approval",
              name: "saf-approval",
              component: () =>
                import("./views/stockOpname/saf/approval/index.vue"),
            },
          ],
        },
        {
          // SAF ROUTE
          path: "saf",
          component: () => import("./views/stockOpname"),
          children: [
            {
              path: "/saf/manage-saf/execute",
              name: "saf-execute",
              component: () => import("./views/stockOpname/saf-execute/index.vue"),
            },
            {
              path: "/saf/manage-saf/execute/view",
              name: "saf-execute-view",
              component: () => import("./views/stockOpname/saf-execute/ViewDetails.vue"),
            }
          ],
        },
        {
          path: "batch-ed",
          component: () => import("./views/stockOpname"),
          children: [
            {
              path: "/batch-ed/manage-batch-ed",
              name: "batch-ed",
              component: () => import("./views/stockOpname/batch-ed/index.vue"),
            },
            {
              path: "/batch-ed/manage-batch-ed/create",
              name: "batch-ed-create",
              component: () =>
                import("./views/stockOpname/batch-ed/CreateV2.vue"),
            },
            {
              path: "/batch-ed/manage-batch-ed/view",
              name: "batch-ed-view",
              component: () =>
                import("./views/stockOpname/batch-ed/ViewDetail.vue"),
            },
            {
              path: "/batch-ed/manage-batch-ed/edit",
              name: "batch-ed-edit",
              component: () =>
                import("./views/stockOpname/batch-ed/CreateV2.vue"),
            },
          ],
        },
        {
          // SOF ROUTE
          path: "stock-opname-form",
          component: () => import("./views/stockOpname"),
          children: [
            {
              path: "/stock-opname-form",
              name: "stock-opname-form",
              component: () => import("./views/stockOpname/sof/index.vue"),
            },
            {
              path: "/stock-opname-form/create",
              name: "stock-opname-form-create",
              component: () => import("./views/stockOpname/sof/Create.vue"),
            },
            {
              path: "/stock-opname-form/view",
              name: "stock-opname-form-view",
              component: () => import("./views/stockOpname/sof/Views.vue"),
            },
            {
              path: "/stock-opname-form/edit",
              name: "stock-opname-form-edit",
              component: () =>
                import("./views/stockOpname/sof/ViewDetails.vue"),
            },
            {
              path: "/stock-opname-form/approval",
              name: "stock-opname-form-approval",
              component: () =>
                import("./views/stockOpname/sof/approval/index.vue"),
            },
          ],
        },
        {
          path: "canvas",
          component: () => import("./views/canvas"),
          children: [
            {
              path: "loading",
              component: () => import("./views/canvas"),
              children: [
                {
                  path: "",
                  name: "canvas.loading",
                  component: () => import("./views/canvas/loading/index.vue"),
                },
                {
                  path: "detail/:id",
                  name: "canvas.loading-detail-new",
                  component: () =>
                    import("./views/canvas/loading/detailNew.vue"),
                },
                {
                  path: "edit/:id",
                  name: "canvas.loading-picking-items",
                  component: () => import("./views/canvas/loading/Form.vue"),
                },
                {
                  path: "detail/handover/:id",
                  name: "canvas.loading-detail-handover",
                  component: () =>
                    import("./views/canvas/loading/detailHandover.vue"),
                },
                {
                  path: "detail-complete/:id",
                  name: "canvas.loading-detail-complete",
                  component: () =>
                    import("./views/canvas/loading/detailComplete.vue"),
                },
              ],
            },
            {
              path: "picking",
              component: () => import("./views/canvas"),
              children: [
                {
                  path: "",
                  name: "canvas.picking",
                  component: () => import("./views/canvas/picking/index.vue"),
                },
                {
                  path: "detail/:id",
                  name: "canvas.picking-detail-new",
                  component: () =>
                    import("./views/canvas/picking/detailNew.vue"),
                },
                {
                  path: "edit/:id",
                  name: "canvas.picking-picking-items",
                  component: () => import("./views/canvas/picking/Form.vue"),
                },
                {
                  path: "detail-complete/:id",
                  name: "canvas.picking-detail-complete-picking",
                  component: () =>
                    import("./views/canvas/picking/detailCompletePicking.vue"),
                },
              ],
            },
            {
              path: "handover",
              component: () => import("./views/canvas"),
              children: [
                {
                  path: "",
                  name: "canvas.handover",
                  component: () => import("./views/canvas/handover/index.vue"),
                },
                {
                  path: "detail/handover/:id",
                  name: "canvas.handover-detail-handover",
                  component: () =>
                    import("./views/canvas/handover/detailHandover.vue"),
                },
                {
                  path: "detail/handovered/:id",
                  name: "canvas.handover-detail-handovered",
                  component: () =>
                    import("./views/canvas/handover/detailHandoverSales.vue"),
                },
                {
                  path: "detail-complete/:id",
                  name: "canvas.handover-detail-complete",
                  component: () =>
                    import("./views/canvas/handover/detailComplete.vue"),
                },
                {
                  path: "emergency",
                  name: "canvas.emergency",
                  component: () =>
                    import("./views/canvas/handover/emergencyForm.vue"),
                },
              ],
            },
            {
              path: "unloading",
              component: () => import("./views/canvas"),
              children: [
                {
                  path: "",
                  name: "canvas.unloading",
                  component: () => import("./views/canvas/unloading/index.vue"),
                },
                {
                  path: "edit-receive/:id",
                  name: "canvas.unloading-edit-receive",
                  component: () =>
                    import("./views/canvas/unloading/formReceive.vue"),
                },
                {
                  path: "edit-putaway/:id",
                  name: "canvas.unloading-edit-check",
                  component: () =>
                    import("./views/canvas/unloading/formCheck.vue"),
                },
                /* {
                  path: "edit-putaway/:id",
                  name: "canvas.unloading-edit-putaway",
                  component: () =>
                    import("./views/canvas/unloading/formPutaway.vue"),
                }, */
                {
                  path: "edit-complete/:id",
                  name: "canvas.unloading-edit-complete",
                  component: () =>
                    import("./views/canvas/unloading/formComplete.vue"),
                },
                {
                  path: "get-sales-unloading",
                  name: "canvas.sales-unloading",
                  component: () =>
                    import("./views/canvas/unloading/unloadingItems.vue"),
                },
              ],
            },
            {
              path: "sales-view",
              component: () => import("./views/canvas"),
              children: [
                {
                  path: "",
                  name: "canvas.sales-view",
                  component: () =>
                    import("./views/canvas/sales-view/index.vue"),
                },
                {
                  path: "create/:id",
                  name: "canvas.sales-view-create",
                  component: () =>
                    import("./views/canvas/sales-view/tempForm.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "scrap",
          component: () => import("./views/scrap"),
          children: [
            //=================== route for stock opname ================
            {
              path: "stock-scrap",
              name: "stock-scrap",
              component: () => import("./views/scrap/stock-scrap/index.vue"),
            },
            {
              path: "stock-scrap/create",
              name: "stock-scrap-create",
              component: () => import("./views/scrap/stock-scrap/create.vue"),
            },
            {
              path: "stock-scrap/import",
              name: "stock-scrap-import",
              component: () => import("./views/scrap/stock-scrap/import.vue"),
            },
            {
              path: "stock-scrap/edit",
              name: "stock-scrap-edit",
              component: () => import("./views/scrap/stock-scrap/create.vue"),
            },
            {
              path: "stock-scrap/view",
              name: "stock-scrap-view",
              component: () => import("./views/scrap/stock-scrap/view.vue"),
            },
            {
              path: "stock-scrap/attach",
              name: "stock-scrap-attach",
              component: () => import("./views/scrap/stock-scrap/show.vue"),
            },
            {
              path: "stock-scrap/print",
              name: "stock-scrap-print",
              component: () => import("./views/scrap/stock-scrap/print.vue"),
            },
            {
              path: "approval",
              name: "stock-scrap-approval",
              component: () => import("./views/scrap/approval/index.vue"),
            },
            {
              path: "execute",
              name: "stock-scrap-execute",
              component: () => import("./views/scrap/execute/index.vue"),
            },
          ],
        },
        {
          path: "setting",
          component: () => import("./views/setting"),
          children: [
            {
              path: "warehouse-configuration",
              component: () => import("./views/setting"),
              children: [
                {
                  path: "",
                  name: "setting.warehouse-configuration",
                  component: () =>
                    import("./views/setting/warehouse-configuration/index.vue"),
                },
              ],
            },
            // user
            {
              path: "users",
              component: () => import("./views/setting"),
              children: [
                {
                  path: "",
                  name: "setting.user",
                  component: () => import("./views/setting/users/index.vue"),
                },
                {
                  path: "create",
                  name: "setting.user.create",
                  component: () => import("./views/setting/users/Form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "setting.user.edit",
                  component: () => import("./views/setting/users/Edit.vue"),
                },
              ],
            },
            {
              path: "role-menu",
              component: () => import("./views/setting/index.vue"),
              children: [
                {
                  path: "",
                  name: "role-menu",
                  component: () =>
                    import("./views/setting/role-menu/index.vue"),
                },
                {
                  path: "add",
                  name: "role-menu-add",
                  component: () => import("./views/setting/role-menu/form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "role-menu-edit",
                  component: () => import("./views/setting/role-menu/form.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "master",
          component: () => import("./views/master"),
          children: [
            {
              path: "buying-price",
              component: () => import("./views/master"),
              children: [
                {
                  path: "",
                  name: "master.buying-price",
                  component: () =>
                    import("./views/master/buying-price/index.vue"),
                },
                {
                  path: "detail-import/:id",
                  name: "master.buying-price.detail-import",
                  component: () =>
                    import("./views/master/buying-price/DetailImport.vue"),
                },
                {
                  path: "detail/:id",
                  name: "master.buying-price.detail",
                  component: () =>
                    import("./views/master/buying-price/FormDetail.vue"),
                },
                {
                  path: "form/:id",
                  name: "master.buying-price.form",
                  component: () =>
                    import("./views/master/buying-price/Form.vue"),
                },
                {
                  path: "form-create",
                  name: "master.buying-price.form-create",
                  component: () =>
                    import("./views/master/buying-price/FormCreate.vue"),
                },
                {
                  path: "form-amend/:id",
                  name: "master.buying-price.form-amend",
                  component: () =>
                    import("./views/master/buying-price/AmendForm.vue"),
                },
                {
                  path: "approval",
                  name: "master.buying-price.approval",
                  component: () =>
                    import("./views/master/buying-price/approval/index.vue"),
                },
              ],
            },
            {
              path: "purchase-discount",
              component: () => import("./views/master"),
              children: [
                {
                  path: "",
                  name: "master.purchase-discount",
                  component: () =>
                    import("./views/master/purchase-discount/index.vue"),
                },
                {
                  path: "approval",
                  name: "master.purchase-discount-approval",
                  component: () =>
                    import(
                      "./views/master/purchase-discount/approval/index.vue"
                    ),
                },
                {
                  path: "approval/detail/:id",
                  name: "master.purchase-discount-approval-detail",
                  component: () =>
                    import(
                      "./views/master/purchase-discount/approval/Detail.vue"
                    ),
                },
                {
                  path: "create",
                  name: "master.purchase-discount-create",
                  component: () =>
                    import("./views/master/purchase-discount/Form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "master.purchase-discount-edit",
                  component: () =>
                    import("./views/master/purchase-discount/FormEdit.vue"),
                },
                {
                  path: "amend/:id",
                  name: "master.purchase-discount-amend",
                  component: () =>
                    import("./views/master/purchase-discount/FormAmend.vue"),
                },
                {
                  path: "view/:id",
                  name: "master.purchase-discount-view",
                  component: () =>
                    import("./views/master/purchase-discount/Form.vue"),
                },
              ],
            },
            {
              path: "sku-view",
              component: () => import("./views/master"),
              children: [
                {
                  path: "",
                  name: "master.sku-view",
                  component: () => import("./views/master/sku-view/index.vue"),
                },
              ],
            },
            // master bill of material
            {
              path: "bill-of-material",
              component: () => import("./views/master"),
              children: [
                {
                  path: "",
                  name: "bill-of-material",
                  component: () =>
                    import("./views/master/bill-of-material/index.vue"),
                },
                {
                  path: "bill-of-material/create",
                  name: "bill-of-material-create",
                  component: () =>
                    import("./views/master/bill-of-material/form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "bill-of-material-edit",
                  component: () =>
                    import("./views/master/bill-of-material/form.vue"),
                },
                {
                  path: "detail/:id",
                  name: "bill-of-material-show",
                  component: () =>
                    import("./views/master/bill-of-material/detail.vue"),
                },
              ],
            },
            {
              path: "delivery-code",
              component: () => import("./views/tms"),
              children: [
                {
                  path: "",
                  name: "delivery-code",
                  component: () =>
                    import("./views/tms/delivery-code/index.vue"),
                },
                {
                  path: "create",
                  name: "delivery-code-create",
                  component: () => import("./views/tms/delivery-code/form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "delivery-code-edit",
                  component: () => import("./views/tms/delivery-code/edit.vue"),
                },
              ],
            },
            {
              path: "shelf-life",
              component: () => import("./views/master/shelf-life/index.vue"),
            },
          ],
        },
        {
          path: "operation",
          component: () => import("./views/operation"),
          children: [
            {
              path: "inter-storage",
              name: "inter-storage",
              component: () =>
                import("./views/operation/inter-storage/index.vue"),
            },
            {
              path: "inter-storage/create",
              // path: "inter-storage",
              name: "inter-storage-create",
              component: () =>
                import("./views/operation/inter-storage/form2.vue"),
            },
            {
              path: "inter-storage/edit/:id",
              name: "inter-storage-edit",
              component: () =>
                import("./views/operation/inter-storage/form2.vue"),
            },
            {
              path: "inter-storage/detail/:id",
              name: "inter-storage-show",
              component: () =>
                import("./views/operation/inter-storage/detail.vue"),
            },
            {
              path: "inter-storage/emergency",
              name: "inter-storage-emergency",
              component: () =>
                import("./views/operation/inter-storage/emergencyForm.vue"),
            },
          ],
        },
        // {
        //   path: "inter-storage",
        //   component: () => import("./views/inter-storage"),
        //   children: [
        //     {
        //       path: "",
        //       name: "inter-storage",
        //       component: () =>
        //         import("./views/inter-storage/inter-storages/index.vue"),
        //     },
        //     {
        //       path: "inter-storage/create",
        //       name: "inter-storage-create",
        //       component: () =>
        //         import("./views/inter-storage/inter-storages/Create.vue"),
        //     },
        //     {
        //       path: "edit/:id",
        //       name: "inter-storage-edit",
        //       component: () =>
        //         import("./views/inter-storage/inter-storages/edit.vue"),
        //     },
        //     {
        //       path: "detail/:id",
        //       name: "inter-storage-show",
        //       component: () =>
        //         import("./views/inter-storage/inter-storages/detail.vue"),
        //     },
        //   ],
        // },
        //work order route
        {
          path: "work-order",
          component: () => import("./views/work-order"),
          children: [
            {
              path: "",
              name: "work-order-ho",
              component: () =>
                import("./views/work-order/work-order-ho/index.vue"),
            },
            {
              path: "work-order/create",
              name: "work-order-ho-create",
              component: () =>
                import("./views/work-order/work-order-ho/Create.vue"),
            },
            {
              path: "edit/:id",
              name: "work-order-ho-edit",
              component: () =>
                import("./views/work-order/work-order-ho/edit.vue"),
            },
            {
              path: "detail/:id",
              name: "work-order-ho-show",
              component: () =>
                import("./views/work-order/work-order-ho/detail.vue"),
            },
            {
              path: "attach/:id",
              name: "work-order-ho-attach",
              component: () =>
                import("./views/work-order/work-order-ho/Attach.vue"),
            },
            // work order execute
            {
              path: "execution",
              name: "work-order-execution",
              component: () =>
                import("./views/work-order/work-order-execute/index.vue"),
            },
            {
              path: "execution/picking-plan/:id",
              name: "work-order-execution-picking",
              component: () =>
                import(
                  "./views/work-order/work-order-execute/PickingPlanForm.vue"
                ),
            },
            {
              path: "execution/picking-plan-detail/:id",
              name: "work-order-execution-picking-plan-detail",
              component: () =>
                import(
                  "./views/work-order/work-order-execute/detailPickingPlan.vue"
                ),
            },
            {
              path: "execution/complete-detail/:id",
              name: "work-order-execution-complete-detail",
              component: () =>
                import(
                  "./views/work-order/work-order-execute/detailComplete.vue"
                ),
            },
            {
              path: "execution/cancel-execution/:id",
              name: "work-order-execution-cancel",
              component: () =>
                import(
                  "./views/work-order/work-order-execute/detailComplete.vue"
                ),
            },
            {
              path: "execution/picking-actual/:id",
              name: "work-order-execution-picking-actual",
              component: () =>
                import("./views/work-order/work-order-execute/PickingForm.vue"),
            },
            {
              path: "execution/execute-form/:id",
              name: "work-order-execution-execute-form",
              component: () =>
                import("./views/work-order/work-order-execute/ExecuteForm.vue"),
            },
            {
              path: "execution/putaway-form/:id",
              name: "work-order-execution-putaway-form",
              component: () =>
                import("./views/work-order/work-order-execute/PutawayForm.vue"),
            },
            // ...
          ],
        },
        {
          path: "import",
          component: () => import("./views/import"),
          children: [
            {
              path: "purchasing-begbal",
              name: "log.import.list",
              component: () =>
                import("./views/import/purchasing-begbal/List.vue"),
            },
            {
              path: "log/:id",
              name: "log.import.detail",
              component: () =>
                import("./views/import/purchasing-begbal/LogImportDetail.vue"),
            },
            {
              path: "structure-org",
              name: "structure-org",
              component: () => import("./views/import/structure-org/index.vue"),
            },
            {
              path: "sku",
              name: "sku",
              component: () => import("./views/import/sku/index.vue"),
            },
            {
              path: "user",
              name: "user",
              component: () => import("./views/import/user/index.vue"),
            },
            {
              path: "customer",
              name: "customer",
              component: () => import("./views/import/customer/index.vue"),
            },
            {
              path: "company",
              name: "company",
              component: () => import("./views/import/company/index.vue"),
            },
          ],
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue"),
        },
        {
          path: "/pages/forgot-password",
          name: "forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
        },
        {
          path: "/pages/reset-password/:id",
          name: "reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
        },
        {
          path: "/version",
          name: "version",
          component: () => import("@/views/pages/version.vue"),
        },
        {
          path: "/changelog",
          name: "changelog",
          component: () => import("./views/pages/changelog.vue"),
        },
        {
          path: "/term-of-service",
          name: "page-term-of-service",
          component: () => import("@/views/pages/TermOfService.vue"),
        },
        {
          path: "/pages/privacy-police",
          name: "page-privacy-police",
          component: () => import("@/views/pages/PrivacyPolice.vue"),
        },
        {
          path: "/privacy-police",
          name: "page-privacy-police",
          component: () => import("@/views/pages/PrivacyPolice.vue"),
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
        },
        {
          path: "/auth/callback",
          name: "auth-callback",
          component: () => import("@/views/auth/callback.vue"),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
    {
      path: "/outbound/simple-sto-do/print/:id",
      name: "outbound.printdata",
      component: () => import("./views/outbound/simple-sto-do/print-pdf.vue"),
    },
    {
      path: "/outbound/simple-outbound-processing/handover/print",
      name: "handover-print",
      component: () =>
        import(
          "./views/outbound/simple-outbound-processing/handover/print.vue"
        ),
    },
    {
      path: "/outbound/simple-outbound-processing/picking/print",
      name: "picking-print",
      component: () =>
        import("./views/outbound/simple-outbound-processing/process/print.vue"),
    },
    {
      path: "/outbound/simple-outbound-processing/picking/print2",
      name: "picking-print",
      component: () =>
        import(
          "./views/outbound/simple-outbound-processing/process/print2.vue"
        ),
    },
    {
      path: "/outbound/simple-outbound-planner/surat-jalan/print",
      name: "print-surat-jalan",
      component: () =>
        import("./views/outbound/simple-outbound-planner/SuratJalan.vue"),
    },
    {
      path: "/outbound/simple-outbound-planner/surat-jalan/print2",
      name: "print-surat-jalan2",
      component: () =>
        import("./views/outbound/simple-outbound-planner/SuratJalan2.vue"),
    },
    {
      path: "/outbound/simple-outbound-planner/surat-jalan/print3",
      name: "print-surat-jalan3",
      component: () =>
        import("./views/outbound/simple-outbound-planner/SuratJalan3.vue"),
    },
    {
      path: "/outbound/simple-outbound-planner/invoice/print",
      name: "print-invoice",
      component: () =>
        import("./views/outbound/simple-outbound-planner/Invoice.vue"),
    },
    {
      path: "/outbound/simple-outbound-processing/pickup/print/:id",
      name: "pickup-print",
      component: () =>
        import("./views/outbound/simple-outbound-processing/pickup/print.vue"),
    },
    {
      path: "/outbound/simple-outbound-processing/pickup/print-pickup-picking-list",
      name: "picking-list-pickup-print",
      component: () =>
        import(
          "./views/outbound/simple-outbound-processing/pickup/pickingListPickupPrint.vue"
        ),
    },
    {
      path: "/outbound/simple-outbound-processing/pickup/print-pickup-picking-list2",
      name: "picking-list-pickup-print2",
      component: () =>
        import(
          "./views/outbound/simple-outbound-processing/pickup/pickingListPickupPrint2.vue"
        ),
    },
    {
      path: "/outbound/stock-transfer-order-processing/print-handover/:id",
      name: "outbound.stock-transfer-order-processing-handover-print",
      component: () =>
        import(
          "./views/outbound/stock-transfer-order-processing/printHandover.vue"
        ),
    },
    {
      path: "/canvas/loading/print-actual/:id",
      name: "canvas.loading-actual-print",
      component: () => import("./views/canvas/loading/printActual.vue"),
    },
    {
      path: "/canvas/handover/print-picking-list/:id",
      name: "canvas.handover-print-picking-list",
      component: () => import("./views/canvas/handover/printPickingList.vue"),
    },
    {
      path: "/work-order/execution/print-picking-plan/:id",
      name: "work-order.execution.print-picking-plan",
      component: () =>
        import("./views/work-order/work-order-execute/printPickingPlan.vue"),
    },
    {
      path: "/work-order/execution/print-execute/:id",
      name: "work-order.execution.print-execute",
      component: () =>
        import("./views/work-order/work-order-execute/printExecute.vue"),
    },
    {
      path: "/work-order/execution/print-putaway/:id",
      name: "work-order.execution.print-putaway",
      component: () =>
        import("./views/work-order/work-order-execute/printPutaway.vue"),
    },
    {
      path: "/supplier-return/print-complete/:id",
      name: "supplier-return.print-complete",
      component: () =>
        import("./views/outbound/supplier-return/printComplete.vue"),
    },
    {
      path: "/operation/inter-storage/print/:id",
      name: "operation.inter-storage.print",
      component: () => import("./views/operation/inter-storage/print.vue"),
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
router.beforeEach(routerGuard);
export default router;
